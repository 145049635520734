<template>
  <div>
    <h4>Fiche Analyse - Section</h4>
    {{ selectedObject }}
    <data-table
      :data-source="sections"
      :headers="headers"
      table-class="table-striped table-hover"
    >
      <template slot="actions">
        <a
          href="javascript:void(0)"
          class="btn btn-outline-primary btn-icon"
          data-toggle="modal"
          data-target="#add-fiche-section"
        >
          <i class="icofont icofont-plus" />
        </a>
      </template>
    </data-table>
          
          
          
    <modal
      title="Ajout d'une section"
      id="add-fiche-section"
      :footer="false"
    >
      <form
        class="form-horizontal"
        @submit.prevent="adding"
      >
        <div
          class="alert alert-danger"
          v-if="has_error"
        >
          {{ error_msg }}
        </div>
        <div class="form-group">
          <label for="add-libelle">Libelle</label>
          <input
            id="add-libelle"
            name="add-libelle"
            type="text"
            class="form-control"
            v-model="libelle"
          >
        </div>
        <div class="form-group">
          <label for="add-categorie">Type</label>
          <select
            name="add-categorie"
            id="add-categorie"
            class="form-control"
            v-model="type"
            v-select="{placeholder:'Selectoinnez la categorie'}"
          >
            <option value="" />
            <option
              v-for="(t,i) in fiches"
              :key="i"
              :value="t.uid"
            >
              {{ t.libelle }}
            </option>
          </select>
        </div>
        <div class="form-group text-right">
          <button
            type="submit"
            class="btn btn-primary"
          >
            Ajouter
          </button>
        </div>
      </form>
    </modal>
  
    <modal
      title="Modification d'une fiche"
      id="update-fiche"
      :footer="false"
    >
      <form
        class="form-horizontal"
        @submit.prevent="editing"
      >
        <div
          class="alert alert-danger"
          v-if="has_error"
        >
          {{ error_msg }}
        </div>
        <div class="form-group">
          <label for="add-libelle">Libelle</label>
          <input
            id="add-libelle"
            name="add-libelle"
            type="text"
            class="form-control"
            v-model="libelle"
          >
        </div>
        <div class="form-group">
          <label for="update-type">Type</label>
          <select
            name="update-type"
            id="update-type"
            class="form-control"
            v-model="type"
            v-select="{placeholder:'Selectoinnez le type de maladie'}"
          >
            <option value="" />
            <option
              v-for="(t,i) in fiches"
              :key="i"
              :value="t.uid"
            >
              {{ t.libelle }}
            </option>
          </select>
        </div>
        <div class="form-group text-right">
          <button
            type="submit"
            class="btn btn-primary"
          >
            Enregistrer
          </button>
        </div>
      </form>
    </modal>
  </div>
</template>
  
  <script>
  import {mapGetters, mapMutations} from 'vuex'
  import DataTable from '../../../../components/dataTable/local.vue'
  import { COMPONENT_TYPE, TEXT_TYPE } from '../../../../components/dataTable/dataType'
  import Modal from '../../../../components/modal.vue'
  import { ADD_SECTION_FICHE, UPDATE_SECTION_FICHE} from '../../../../graphql/ficheAnalyse'
  const Fiche = () => import('../../../../components/admin/sanitaire/ficheAnalyse/fiche.vue')
  const Actions = () => import('../../../../components/admin/sanitaire/ficheAnalyse/sectionAction.vue')

  export default {
      components: { DataTable, Modal},
      data(){
          return {
              type: null,
              libelle: null,
              has_error: false,
              error_msg: null
          }
      },
      watch: {
        selectedObject: {
              handler(){
                  if(this.selectedObject !== null) {
                      this.libelle = this.selectedObject.libelle
                      this.type = this.selectedObject.fiche
                  }
              },
              deep: true
          }
      },
      methods: {
          ...mapMutations({
              done: 'DONE',
              setSelectedObject: 'SET_SELECTED_OBJECT'
          }),
          initForm(){
              this.setSelectedObject(null)
              this.libelle = null
              this.type = null
              this.has_error = false
              this.error_msg = null
          },
          adding(){
              let data = {
                  libelle: this.libelle,
                  fiche: this.type
              }
              this.$apollo.mutate({
                  mutation: ADD_SECTION_FICHE,
                  variables: {
                      "section": {
                          ...data
                      }
                  },
                  update: (d) => {
                      console.log(d)
                      console.log(`Section ${this.libelle} add successfully`)
                  }
                }).then(() => {
                  this.initForm()
                  this.done()
              }).catch((error) => {
                  console.error(error)
                  this.has_error = true
                  this.error_msg = error
              })
          },
          editing(){
              let data = {
                  libelle: this.libelle,
                  fiche: this.type
              }
              this.$apollo.mutate({
                  mutation: UPDATE_SECTION_FICHE,
                  variables: {
                      "section": {
                          ...data
                      },
                      "uid": this.selectedObject.uid
                  },
                  update: (d) => {
                      console.log(d)
                      console.log(`Section ${this.libelle} on ${this.selectedObject.uid} updated successfully`)
                  }
                }).then(() => {
                  this.initForm()
                  this.done()
              }).catch((error) => {
                  console.error(error)
                  this.has_error = true
                  this.error_msg = error
              })
          }
      },
      computed: {
          ...mapGetters({
              selectedObject: 'selectedObject',
              sections: 'sanitaire/ficheSections',
              fiches: 'sanitaire/ficheAnalyses',
          }),
          headers(){
              return [
                  {label: 'ID', name: 'uid', type: TEXT_TYPE},
                  {label: 'Libelle', name: 'libelle', type: TEXT_TYPE, sortable: true},
                  {label: 'Fiche', name: 'type', type: COMPONENT_TYPE, component: Fiche},
                  {label: 'Actions', name: 'action', type: COMPONENT_TYPE, component: Actions}
              ]
          }
      }
  }
  </script>
  
  <style>
  
  </style>